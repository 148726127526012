.section_equipe{
    display: block;
    width: 100%;

	& .bloco_corretores{
		display: block;
		width: 100%;
		text-align: center;
	
		& h1{
			text-align: center;
			font-weight: 600;
			font-size: 1.8rem;
			color: $colorPrimary;
			margin-bottom: 2rem;
			line-height: 2rem;
		}
		
		& .container_miniaturas {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;
			align-items: flex-start;
			justify-content: center;
			gap: 5rem;

			& .miniatura_equipe{
				display: block;
				width: 25%;
				margin: 0;
				
				& .img{
					display: block;
					width: calc(420px / 1.8);
					height: calc(420px / 1.8);
					text-align: center;
					border: solid 5px $colorTerciary;
					border-radius: 50%;
					overflow: hidden;
					position: relative;

					& img{
						width: calc(420px / 1.8);
						height: calc(420px / 1.8);
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						object-fit: cover;
					}
				}
		
				& .detalhes{
					display: block;
					width: 100%;
		
					& h3{
						font-size: 1rem;
						font-weight: 600;
						color: $colorPrimary;
						text-transform: uppercase;
						text-align: center;
					}
			
					& p{
						font-size: .9rem;
						font-weight: 400;
						color: $gray2;
						text-align: justify;
						line-height: 1.3rem;
					}
				}
			}
		}
	}

}

@media (min-width: 1024px) and (max-width: 1366px){
	.section_equipe{
		& .bloco_corretores{
			& h1{
				font-size: 1.8rem;
			}
			
			& .container_miniaturas {
				& .miniatura_equipe{
					& .img{
						width: calc(420px / 2);
						height: calc(420px / 2);
	
						& img{
							width: calc(420px / 2);
							height: calc(420px / 2);
						}
					}
				}
			}
		}
	}
}

@media (max-width: 1023px){
	.section_equipe{
		& .bloco_corretores{
			& h1{
				font-size: 1.8rem;
			}
			
			& .container_miniaturas {
				flex-direction: column;

				& .miniatura_equipe{
					width: 90%;
					margin: auto;

					& .img{
						width: calc(420px / 2);
						height: calc(420px / 2);
	
						& img{
							width: calc(420px / 2);
							height: calc(420px / 2);
						}
					}
				}
			}
		}
	}
}