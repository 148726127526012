.containerModalidadesLocacao {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    & h1 {
        font-size: 1.75rem;
        font-weight: 600;
        color: $colorPrimary;
        text-align: center;
        margin: 0;
    }

    & h2 {
        font-size: 1rem;
        font-weight: 400;
        color: $gray2;
        text-align: center;
        line-height: 1.5rem;
        max-width: 70%;
        margin: 0 auto;
    }

    & .cardsModalidades {
        margin-top: 1rem;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        align-items: stretch;
        width: 100%;

        & .card {
            width: 100%;
            height: 100%;
            border-radius: .5rem;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
            padding: 1.5rem;


            & .card-header {
                & h3 {
                    font-size: 1rem;
                    font-weight: 600;
                    color: $colorPrimary;
                    margin: 0;
                }
            }

            & .card-body {
                & p {
                    font-size: .875rem;
                    font-weight: 400;
                    color: $gray2;
                    line-height: 1.5rem;
                    margin: .5rem 0;

                    & a {
                        font-size: .875rem;
                        font-weight: 400;
                        color: $gray2;
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1366px){
    .containerModalidadesLocacao {
        & h2 {
            max-width: 80%;
        }
    
        & .cardsModalidades {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media (max-width: 1023px){
    .containerModalidadesLocacao {
        & h2 {
            max-width: 100%;
        }
    
        & .cardsModalidades {
            grid-template-columns: 1fr;
        }
    }
}