.container_pag_videos {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 1rem;
}

.miniatura-imovel-video {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;

	& .miniatura-imovel-img-video {
		border-radius: .2rem .2rem 0 0;
		display: block;
		width: 100%;
		padding-top: 70%;
		overflow: hidden;
		background-color: $gray1;
		position: relative;
		user-select: none;
		cursor: pointer;

		& img {
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			& i {
				font-size: 4rem;
				color: white;
				font-weight: 500;
				text-shadow: 0 1px black;
			}
		}
	}

	& .miniatura-imovel-detalhe {
		border: solid 1px $gray4;
		border-radius: 0 0 .2rem .2rem;
		padding: 1rem;

		& p {
			display: inline-block;
			width: 70%;
			text-align: left;
			color: $gray1;
			font-size: .9rem;
			font-weight: 500;
		}

		& button {
			display: inline-block;
			width: 30%;
			text-align: center;
			cursor: pointer;
			border: solid 1px $colorTerciary;
			background-color: $colorTerciary;
			color: black;
			font-size: .9rem;
			font-weight: 600;
			border-radius: $borderRadius;
			padding: .3rem 0;
			transition: all .2s ease-in-out;
			text-transform: uppercase;

			&:hover {
				background-color: lighten($colorTerciary, 5);
			}
		}
	}
}

.miniatura-imovel-video_interna {
	display: inline-block;
	vertical-align: top;
	margin-bottom: 2rem;

	& .miniatura-imovel-img-video {
		border-radius: .2rem .2rem 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		background-color: $gray4;
		position: relative;
		user-select: none;

		& img {
			width: 100%;
			display: block;
			object-fit: none;
		}

		& .miniatura-video-player {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);

			& i {
				font-size: 4rem;
				color: white;
				font-weight: 500;
				text-shadow: 0 1px black;
			}
		}
	}

	& .miniatura-imovel-detalhe {
		border: solid 1px $gray4;
		border-radius: 0 0 .2rem .2rem;
		padding: 1rem;

		& button {
			display: inline-block;
			width: fit-content;
			text-align: center;
			cursor: pointer;
			border: solid 1px $colorTerciary;
			background-color: $colorTerciary;
			color: white;
			font-size: .9rem;
			font-weight: 600;
			border-radius: $borderRadius;
			padding: .3rem 1rem;
			transition: all .2s ease-in-out;

			&:hover {
				background-color: lighten($colorTerciary, 5);
			}
		}
	}
}

@media (max-width: 1023px) {
	.container_pag_videos {
		grid-template-columns: 1fr;
	}
}