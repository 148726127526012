.containerPagina {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    & h3 {
        margin: 0 0 1rem 0;
        color: $colorPrimary;
        font-weight: 600;
        font-size: 1.1rem;
    }

    & p {
        margin: 0;
        line-height: 1.75rem;
        color: $gray2;
        font-weight: 400;
        font-size: .9rem;
    }

    & ol {
        margin: 0;
        padding: 0 0 0 2rem;
        list-style-type: decimal;
        line-height: 1.75rem;
        color: $gray2;
        font-weight: 400;
        font-size: .9rem;

        & li {
            list-style-type: decimal;
            
            &::marker {
                color: $colorPrimary;
            }
        }
    }
}