.miniatura-imovel {
	display: inline-block;
	vertical-align: top;

	& .miniatura-imovel-img{
		border-radius: $borderRadius $borderRadius 0 0;
		display: block;
		width: 100%;
		overflow: hidden;
		padding-top: 90%;
		background-color: $gray5;
		position: relative;
		user-select: none;
		border: solid 1px lighten($gray4, 10);
		border-bottom: none;
		transition: all .2s ease-in-out;

		& img{
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			object-fit: fill;
		}

		& .miniatura-imv-status{
			top: .5rem;
			left: .5rem;
			position: absolute;

			& p{
				background-color: white;
				border-radius: $borderRadius;
				font-size: .8rem;
				font-weight: 600;
				line-height: inherit;
				text-align: center;
				color: $gray1;
				padding: .1rem .5rem;
				margin: 0
			}
		}

		& .miniatura-cod{
			position: absolute;
			top: .5rem;
			right: .5rem;

			& p{
				background-color: white;
				border-radius: $borderRadius;
				font-size: .8rem;
				font-weight: 600;
				line-height: inherit;
				text-align: center;
				color: $gray1;
				padding: .1rem .5rem;
				margin: 0
			}
		}
	}

	& .miniatura-imovel-detalhe{
		display: flex;
		flex-direction: column;
		border: solid 1px lighten($gray4, 10);
		border-top: none;
		border-radius: 0 0 $borderRadius $borderRadius;
		width: 100%;
		padding: 1rem;
		background-color: white;
		@include border-box;
		transition: all .2s ease-in-out;

		& .miniatura-titulo{
			display: block;
			width: 100%;

			& .miniatura_title{
				text-transform: capitalize;
				font-size: .9rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;
				line-height: 1.5rem;
				height: 3rem;
			}

			& .miniatura_endereco{
				display: block;
				width: 100%;

				& p{
					text-transform: initial;
					font-size: .8rem;
					font-weight: 500;
					color: $gray2;
					& i{
						font-size: .7rem;
						font-weight: 400;
						color: $colorTerciary;
					}
				}
			}
		}

		& .miniatura-acao-tipo {
			width: 100%;
			
			& p{
				text-transform: initial;
				font-size: .8rem;
				font-weight: 400;
				color: $gray2;
				margin: 0;
			}
		}

		& .miniatura-imovel-btns-icons{
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			gap: .5rem;
			height: 5rem;
			width: 100%;

			& ul{
				& li{
					font-size: .85rem;
					color: $gray2;
					font-weight: 400;

					& i{
						color: $colorTerciary;
						font-weight: 400;
						font-size: 1.1rem;
					}
				}
			}
		}
		
		& .miniatura-preco{
			display: block;
			width: 100%;

			& p{
				width: 100%;
				padding-top: 1rem;
				text-align: left;
				font-size: 1.2rem;
				font-weight: 600;
				color: $gray1;
				margin: 0;
			}
		}
	}

	&:hover{
		& .miniatura-imovel-img{
			border-color: darken($gray4, 5);
		}

		& .miniatura-imovel-detalhe{
			border-color: darken($gray4, 5);
		}
	}
}

.splide__arrow--prev{
	left: -3rem !important;
}

.splide__arrow--next{
	right: -3rem !important;
}

/* SÓ SE APLICA NO PC */
@media (min-width: 1024px) {
	.iwidth-100{
		width: 100% !important;
	}
	.iwidth-50{
		width: 50% !important;
	}
	.iwidth-33-3{
		width: 33.333333% !important;
	}
	.iwidth-25{
		width: 25% !important;
	}
	.iwidth-20{
		width: 20% !important;
	}

}

@media (min-width: 1201px) and (max-width: 1366px){
	.splide__arrow--prev{
		left: 1.5rem !important;
	}
	
	.splide__arrow--next{
		right: 1.5rem !important;
	}

	.miniatura-imovel {
		& .miniatura-imovel-img{
			padding-top: 100%;
		}
	}
}

@media (min-width: 1024px) and (max-width: 1200px){
	.splide__arrow--prev{
		left: 0 !important;
	}
	
	.splide__arrow--next{
		right: 0 !important;
	}

	.miniatura-imovel {
		& .miniatura-imovel-img{
			padding-top: 100%;
		}
	}
}

@media (max-width: 1023px){
	.miniatura-imovel {
		& .miniatura-imovel-img{
			padding-top: 100%;
		}
	}
}

