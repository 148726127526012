.formsimple-input-bc {
	background-color: transparent;
	border: solid 1px lighten($gray4, 10);
	border-radius: .3rem;
	color: $gray2;
	display: inline-block;
	padding: 0 1rem;
	margin: 0;
	transition: all .2s ease-in-out;

	&:hover, &:focus {
		border-color: $gray4;
	}

	&::placeholder {
		color: $gray4 !important;
	}
}