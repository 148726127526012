.header1{
	width: 100%;
	background-color: transparent;
}

.header1_2{
	background-color: white;
	width: 100%;
	
	& p{
		color: $gray1 !important;
	}

	& i{
		color: $colorTerciary !important;
	}

	& button{
		color: $colorPrimary !important;
		& i{
			color: $colorTerciary !important;
		}
	}

	.headerpc {
		& .superior {
			& .sessions {
				& ul {
					& .itens_header2{
						& .itemsession{
							& .title_is{
								& .itemsession_title{
									& i{
										color: $colorTerciary !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

.headerpc {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 1rem;
	width: 70%;
	margin: auto;
	position: relative;
	padding: .75rem 0;

	& .topHeader {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		gap: 3rem;

		& .itensTopHeader {
			margin: 0;
			width: fit-content;

			& p {
				margin: 0;
				font-size: .85rem;
				font-weight: 300;
				text-transform: uppercase;
				color: $colorPrimary;
				transition: color .2s ease-in-out;
			}

			& a {
				& p {
					&:hover{
						color: black;
					}
				}
			}
		}
	}

	& .superior {
		width: 100%;
		display: grid;
		grid-template-columns: 1fr 10fr;
		gap: 1rem;

		/* GRUPO HEADER */
		& .logo {
			width: 100%;
			& .img{
				width: calc(801px / 7);
				height: calc(801px / 7);
				display: block;
				
				& img {
					display: block;
					user-select: none;
					width: calc(801px / 7);
					height: calc(801px / 7);
				}
			}
		}

		& .sessions {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: 1.5rem;

			& ul {
				width: 100%;
				display: flex;
				align-items: center;
				justify-content: flex-end;
				margin: 0%;
				padding: 0%;
				gap: 1.5rem;

				& .itens_header{
					& a{
						display: inline-block;
						vertical-align: middle;
						& p{
							color: $colorPrimary;
							font-weight: 600;
							font-size: 1rem;
							margin: 0;
							padding: 0;
							transition: color .2s ease-in-out;

							& i{
								color: $colorPrimary;
								font-size: 2rem;
								vertical-align: middle;
								padding: 0 .2rem;
								margin: 0;
								font-weight: 400;
								transition: color .2s ease-in-out;
							}
						}

						&:hover {
							& p {
								color: black;

								& i {
									color: black;
								}
							}
						}
					}
				}
			}
		}
	}
}

.btn_menu_header{
	background-color: transparent !important;
	color: $colorPrimary !important;
	border: none !important;
}

.headerindex {
	height: calc(100vh - 10.8rem);
	display: block;
	width: 100%;
	padding: 0;
	@include border-box;
	z-index: 2;
	
	& .container-bg-headerpc{
		display: block;
		position: absolute;
		top:0;
		overflow: hidden;
		z-index: -1;
		height: 100%;
		width: 100%;
		background-color: $colorPrimary;
		
		/* & .overlay_bg_home{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background: linear-gradient(to bottom, rgba(black, .65), rgba(black, .1), transparent);
			z-index: 2;
		} */

		& .bg-headerpc{
			background-color: $colorPrimary;
			display: block;
			height: 100%;
			width: 100%;
			z-index: 2;
			background-image: url('/jnh/header/bg2.jpg');
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center top 50%;
			/* filter: hue-rotate(30deg); */
		}
	}
}

.header-mob{
	background-color: transparent;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: .5rem 1rem;

	& .log{
		width: 100%;
		margin: 0;
		width: calc(801px / 12);
		height: calc(801px / 12);
		display: block;
		
		& img {
			display: block;
			user-select: none;
			width: calc(801px / 12);
			height: calc(801px / 12);
		}
	}
}


/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin header--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1200px
	min-width: 1024px
*/
@mixin header--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin header--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin header--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin header--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin header--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin header--smart-old(){
}

@media (min-width: 1024px) and (max-width: 1366px){

	.headerpc {
		width: 85%;
		& .superior {
			grid-template-columns: 1fr 10fr;

			& .logo {
				& .img{
					width: calc(801px / 8);
					height: calc(801px / 8);
					
					& img {
						width: calc(801px / 8);
						height: calc(801px / 8);
					}
				}
			}
		}
	}

	.headerindex {
		height: calc(100vh - 10rem);
		& .container-bg-headerpc{
			& .bg-headerpc{
				background-position: center top 40%;
			}
		}
	}
}

@media (max-width: 1023px){
	
	.headerindex {
		height: calc(100vh - 5.7rem);
	}
}
