.containerDocumentosNecessarios {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3rem;

    & h1 {
        font-size: 1.75rem;
        font-weight: 600;
        color: $colorPrimary;
        text-align: center;
        margin: 0;
    }

    & .cardsDocumentos {
        display: flex;
        flex-direction: column;
        gap: 3rem;
        width: 100%;

        & .card {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;

            & .card-header {
                width: 100%;
                & h3 {
                    font-size: 1rem;
                    font-weight: 600;
                    color: $colorPrimary;
                    margin: 0 0 1rem 0;
                }
            }

            & .card-body {
                width: 100%;
                display: flex;
                flex-direction: column;
                margin: 0 !important;

                & li {
                    font-size: .875rem;
                    font-weight: 400;
                    color: $gray2;
                    line-height: 1.5rem;
                    list-style-type: disc;

                    &::marker {
                        color: $colorTerciary;
                    }
                }
            }
        }
    }

    & .contatoDocumentos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin: 0;

        & p {
            font-size: .9rem;
            font-weight: 400;
            color: $gray2;
            line-height: 1.5rem;
            margin: 0;

            & a{
                font-size: .9rem;
                font-weight: 600;
                color: $colorPrimary;
                text-decoration: none;
            }
        }
    }

    & .infoDocs {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        width: 100%;
        margin: 0;

        & h2 {
            font-size: 1rem;
            font-weight: 600;
            color: $colorPrimary;
            text-align: left;
            line-height: 1.5rem;
            margin: 0;
        }
    
        & ol {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin: 0 !important;
    
            & li {
                font-size: .875rem;
                font-weight: 400;
                color: $gray2;
                line-height: 1.5rem;
                list-style-type: disc;
    
                &::marker {
                    color: $colorTerciary;
                }
            }
        }
    }
}