.busca {
	display: block;
	width: 65%;
	text-align: center;
	z-index: 2;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);

	& .slogan {
		display: block;
		width: 100%;
	
		& h1{
			font-size: 1.8rem;
			font-weight: 600;
			color: white;
			text-align: center;
			text-shadow: 1px 1px 3px black;
		}
	}

	& .busca_inferior{
		display: block;
		width: 100%;
	
		& .bloco_busca{
			width: 100%;
			display: grid;
			grid-template-columns: 1.2fr 1.5fr 1.5fr 1fr 1fr;
			gap: 1rem;
			box-sizing: border-box;
			background-color: rgba(black, .8);
			padding: 1rem;
			border-radius: $borderRadius;

			& .item_busca {
				width: 100%;
				display: block;
				margin: 0;

				& .formsimple-selectmultiple {
					
					& .label {
						border: solid 1px $gray3 !important;
						color: white !important;
						
						&:hover, &:focus {
							border-color: $gray4 !important;
						}
					}
	
					& .search {
						& input {
							border: solid 1px transparent !important;
							border-left: solid 3px $colorTerciary !important;
							color: $gray2 !important;
						}
					}

					& select {
						border: solid 1px $gray3 !important;
						color: white;
					}
				}

				& input {
					border: solid 1px $gray3 !important;
					color: whtie;
				}

				& .btn-home{
					padding: .65rem 0;
					border-radius: $borderRadius;
					width: 100%;
					display: inline-block;
					vertical-align: middle;
					background-color: $colorTerciary;
					color: black;
					font-size: .9rem;
					font-weight: 600;
					border: none;
					cursor: pointer;
					transition: all .2s ease-in-out;

					& i {
						font-weight: 600;
						font-size: .8rem;
					}
					
					&:hover{
						background-color: lighten($colorTerciary, 5);
					}
				}
			}
		}
	}
}

.botao_buscar_int{
	text-align: center;
	padding: .45rem 0;
	border: solid 1px $colorTerciary;
	border-radius: .2rem;
	background-color: $colorTerciary;
	color: black;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;
	&:hover{
		background-color: lighten($colorTerciary, 5);
	}
}

.botao_limpar_int{
	text-align: center;
	padding: .45rem 0;
	border: solid 1px lighten($gray4, 10);
	border-radius: .2rem;
	background-color: white;
	color: $gray1;
	font-size: 1.2rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;
	&:hover{
		border-color: $colorTerciary;
	}
}

#render-busca {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	gap: 0;

	& .miniatura-imovel {
		width: 100% !important;
	}
}

.container_infras_buscaInterna {
	display: block;
	width: 100%;
	border: solid 1px lighten($gray4, 10);
	border-radius: .2rem;
	
	& .titleInfraGroup {
		font-size: .9rem;
		font-weight: 400;
		color: $gray1;
		padding: .7rem 1rem;
		margin: 0;
		position: relative;
		cursor: pointer;
		transition: all .2s ease-in-out;
		user-select: none;
		border-radius: .3rem;

		& i{
			position: absolute;
			right: 1.5rem;
			color: $colorTerciary;
			top: 40%;
			transition: all .2s ease-in-out;
		}

		&:hover{
			background-color: lighten($gray5, 2);
		}
	}

	& .infraGroups{
		padding: .5rem;

		& label{
			font-size: .9rem !important;
			& span{
				width: 11px !important;
				height: 11px !important;
				float: none !important;
			}
		}
	}
}

.btns_busca_filtros_mob {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	gap: .5rem;
}

.filtros_avancados_e_ordenar {
	width: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;

	& .filtros_ord {
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		gap: 1rem;
		margin: 0;
	}
}

@media (min-width: 1024px) and (max-width: 1366px){

	.busca {
		display: block;
		width: 85%;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);

		& .busca_inferior{
			& .bloco_busca{
				grid-template-columns: 1.2fr 1.7fr 1.7fr 1fr 1fr;
				
				& .item_busca {
					& .btn-home{
						font-size: .9rem;
					}
				}
			}
		}
	}

	.botao_buscar_int{
		font-size: 1.1rem;
		padding: .47rem 0;
	}
	
	.botao_limpar_int{
		font-size: 1.1rem;
		padding: .47rem 0;
	}
}


@media (max-width: 1023px){

	#render-busca {
		grid-template-columns: repeat(2, 1fr);
	
		& .miniatura-imovel {
			width: 100% !important;
		}
	}

	#busca_container {
		display: block;
		width: 100%;
		overflow: auto;
		position: fixed;
		top: 0;
		left: 0;
		height: 100vh;
		@include border-box;
		z-index: 99999;

		& .busca_btn_close {
			position: absolute;
			top: 1rem;
			right: 3rem;
			line-height: inherit;
		}
	}

	.busca {
		background-color: transparent;
		display: block;
		width: 90%;
		padding: 0;
		text-align: center;
		z-index: 9998;

		& .slogan{
			display: block;
			width: 100%;
			margin: auto;
		
			& h1{
				font-size: 1.3rem;
				line-height: 1.7rem;
			}
		}
	
		& .busca_inferior{
			& .bloco_busca{
				grid-template-columns: 1fr;
				gap: 1.5rem;
	
				& .item_busca {
					width: 100%;

					& .btn-home{
						padding: .8rem 0;
					}
				}
			}
		}
	}

	.filtros_avancados_e_ordenar {
		flex-direction: column;
		gap: 2rem;
		
		& .filtros_ord {
			width: 100%;
			flex-direction: column;
			align-items: flex-end;
			justify-content: flex-end;
			gap: 0;
			margin: 0;
		}
	}
}

@media (max-width: 767px){ 
	#render-busca {
		grid-template-columns: repeat(1, 1fr);
	
		& .miniatura-imovel {
			width: 100% !important;
		}
	}
}
