.title_condomi{
	display: block;
	width: 100%;
	
	& h2{
		text-align: center;
		font-size: 1.75rem;
		font-weight: 600;
		color: $colorPrimary;
		text-transform: uppercase;
	}

	& .under_p{
		text-align: center;
		width: 10%;
		height: 1px;
		margin: auto;
		background-color: $colorTerciary;
		border-radius: 2rem;
	}
}

.title_condomi2{
	display: block;	
	width: 100%;

	& h2{
		font-size: 1.75rem;
		font-weight: 600;
		color: $colorPrimary;
		text-align: center;
		padding-bottom: .5rem;
		text-transform: uppercase;
		margin: 0;
	}

	& .under_p{
		text-align: center;
		width: 10%;
		height: 1px;
		margin: auto;
		background-color: $colorTerciary;
		border-radius: 2rem;
	}
}

.section_sobre {
	background: linear-gradient(135deg, white 65%, $colorPrimary 65%);
	background-color: white;
	display: block;
	width: 100%;

	& .container_sobre {
		display: grid;
		grid-template-columns: 1.5fr 1fr;
		gap: 2rem;
		align-items: center;

		& .conteudo {
			width: 80%;
			margin: 0 auto;

			& h2 {
				font-size: 1.5rem;
				font-weight: 600;
				color: $colorTerciary;
			}

			& p {
				font-size: 1rem;
				font-weight: 400;
				color: $gray2;
			}

			& ol {
				margin-top: 0;

				& li {
					list-style: disc;
					font-size: 1rem;
					font-weight: 400;
					color: $gray2;
					line-height: 1.5rem;

					&::marker{
						color: $colorTerciary;
					}
				}
			}

			& .btn_vermais {
				width: 100%;
				margin: 0;

				& a {
					width: fit-content;
					display: block;
					margin: 0;

					& p {
						background-color: $colorPrimary;
						color: white;
						font-size: 1rem;
						font-weight: 600;
						padding: .3rem 1.5rem;
						border-radius: $borderRadius;
						transition: background-color .2s ease-in-out;

						& i {
							color: $colorTerciary;
							font-size: 1.2rem;
							vertical-align: middle;
						}

						&:hover {
							background-color: darken($colorPrimary, 2);
						}
					}
				}
			}

			& .infos_sobre {
				display: grid;
				grid-template-columns: 1fr 1fr;
				gap: 2rem 3rem;

				& .info_imob{
					display: flex;
					align-items: center;
					justify-content: space-between;
					width: 100%;
			
					& .img{
						width: calc(256px / 6.5);
						height: calc(256px / 6.5);
						display: inline-block;
						vertical-align: middle;
			
						& img{
							width: calc(256px / 6.5);
							height: calc(256px / 6.5);
						}
					}
			
					& .descricao{
						width: 100%;
			
						& .title{
							margin: 0;
							font-size: .9rem;
							color: $colorPrimary;
							font-weight: 600;
							margin: 0;
						}
						
						& p{
							padding-left: 1rem;
							color: $gray2;
							font-size: .9rem;
							font-weight: 400;
							margin: 0;
						}
			
						& a{
							width: fit-content;
							display: block;
							margin: 0;

							& p{
								color: $gray2;
								font-size: .9rem;
								font-weight: 400;
								margin: 0;
								background-color: transparent;
							}
						}
					}
				}
			}

			& .redes_sociais {
				width: 100%;
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				gap: 2rem;

				& .item_rs {
					width: fit-content;
					display: block;
					margin: 0;

					& a {
						background-color: $colorTerciary;
						padding: 1.3rem;
						border-radius: 100%;
						display: block;
						margin: 0;
						position: relative;
						transition: all .2s ease-in-out;
						top: 0;

						& i {
							color: white;
							font-size: 1.2rem;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}

						&:hover {
							background-color: lighten($colorTerciary, 5);
							top: -4px;
							box-shadow: 0px 5px 4px 1px rgba($colorPrimary, .5);
						}
					}
				}
			}

		}

		& .imagem_sobre {
			width: 100%;
			margin: 0;
			text-align: center;

			& img {
				border-radius: $borderRadius;
				width: calc(1080px / 2.5);
				height: calc(1621px / 2.5);
				-webkit-user-drag: none;
			}
		}
	}
}

.section_imoveis_por_regiao {
	background-color: $colorPrimary;
	display: block;
	width: 100%;

	& .title_imo_regiao {
		display: block;
		width: 100%;
		
		& h2{
			text-align: center;
			font-size: 1.75rem;
			font-weight: 600;
			color: white;
			margin: 0;
			padding-bottom: .7rem;
			text-transform: uppercase;
		}
	
		& .under_p{
			text-align: center;
			width: 10%;
			height: 1px;
			margin: auto;
			background-color: $colorTerciary;
			border-radius: 2rem;
		}
	}

	& .container_regioes {
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		width: 100%;
		gap: 2rem;

		& a {
			width: 100%;
			display: block;
			transition: all .15s ease-out;
			position: relative;
			top: 0;
			border: solid 1px rgba($colorTerciary, .3);
			padding: 1rem;
			border-radius: $borderRadius;
		
			& .miniatura_regiao {
				width: 100%;
				display: block;

				& .localizacao {
					width: 100%;
					display: block;

					& .bairro {
						color: $colorTerciary;
						font-size: 1rem;
						font-weight: 600;
						margin: 0;
					}

					& .cid_est {
						color: white;
						font-size: .85rem;
						font-weight: 400;
					}
				}

				& .total_imoveis {
					& p {
						color: $gray4;
						font-size: .8rem;
						font-weight: 400;
						margin: 0;
					}
				}
			}

			&:hover {
				box-shadow: 0px 5px 5px 3px darken($colorPrimary, 5);
				top: -4px;
				border-color: rgba($colorTerciary, .5);
			}
		}
	}
}

.section_servicos{
	width: 100%;
	display: block;
	background: white;
	
	& .container_servicos{
		width: 80%;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		gap: 2.5rem;

		& .bloco_servico{
			width: 100%;
			height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			text-align: left;
			padding: 1rem;
			border-radius: .3rem;
			transition: border-color .2s ease-in-out;
			border: solid 1px $gray5;
			box-shadow: 0 0 10px 1px rgba($gray3, .1);
			background-color: white;

			& .img{
				width: 65px;
				height: 65px;
				display: block;
				margin: 0;

				& img{
					text-align: left;
					width: 65px;
					height: 65px;
				}
			}

			& .descricao{
				width: block;
				display: block;
				position: relative;

				& h2{
					color: $colorPrimary;
					font-size: 1.1rem;
					font-weight: 600;
					text-align: left;
					line-height: 1.5rem;
				}

				& p{
					padding: 0;
					color: lighten($gray2, 5);
					font-size: .9rem;
					font-weight: 400;
					text-align: left;	
				}
			}

			& .btn_avancar {
				width: 100%;
				display: block;
				margin: 0;

				& a {
					display: flex;
					flex-direction: row;
					justify-content: space-between;
					align-items: center;
					margin: 0;
					padding: .5rem 0;
	
					& p {
						margin: 0;
						font-size: 1rem;
						font-weight: 500;
						color: $gray1;
					}
	
					& i {
						color: $colorTerciary;
						font-size: 1.2rem;
					}

				}
				
				&::after {
					content: '';
					width: 50%;
					height: 1px;
					background: linear-gradient(to right, lighten($gray4, 10), transparent);
					display: block;
					transition: all .25s ease-in-out;
				}

				&:hover {
					&::after {
						width: 100%;
						background: linear-gradient(to right, lighten($gray4, 10), $colorTerciary);
					}
				}
			}
		}
	}
}

.bg_busca_interna{
	background-color: white;
	display: block;
	width: 100%;

	& h2{
		text-align: center;
		color: $colorPrimary;
		font-size: 1.8rem;
		font-weight: 400;
		margin-top: 0;
	}


	& .container_filtros_busca_interna{
		display: grid;
		grid-template-columns: repeat(5, 1fr);
		gap: 3rem 2rem;

		& .grid_items{
			width: 100%;

			& .titulo-formulario{
				color: $gray2;
				font-size: .9rem;
				font-weight: 400;
				line-height: 1.5rem;
			}

			& .grid_botoes_buscar_limpar{
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				gap: 1rem;
			}
		}
	}
}


.botao_voltar{
	background-color: white;
	border: solid 1px lighten($gray4, 10);
	border-radius: $borderRadius;
	padding: .3rem 1rem;
	color: $gray1 !important;
	font-size: .9rem;
	font-weight: 600;
	cursor: pointer;
	transition: all .2s ease-in-out;

	& i {
		color: $colorTerciary;
	}

	&:hover{
		border-color: $colorTerciary;
	}
}

.title_contato{
	font-size: 1.5rem;
	font-weight: 600;
	color: $colorPrimary;
	vertical-align: middle;
	& i{
		color: $colorTerciary;
	}
}

.width_vid{
	width: 50vw !important;
}

@media (min-width: 1024px) and (max-width: 1366px){

	.bg_busca_interna{
		& .container_filtros_busca_interna{
			gap: 2.5rem 1rem;
	
			& .grid_items{
				width: 100%;
	
				& .titulo-formulario{
					color: $gray4;
					font-size: .9rem;
					font-weight: 400;
					line-height: 1.5rem;
				}
	
				& .grid_botoes_buscar_limpar{
					display: grid;
					grid-template-columns: repeat(2, 1fr);
					gap: 1rem;
				}
			}
		}
	}

	.section_sobre {
		background: linear-gradient(135deg, white 68%, $colorPrimary 68%);
		& .container_sobre {
			gap: 1rem;
	
			& .conteudo {
				width: 85%;
	
				& p {
					font-size: .9rem;
				}
	
				& strong {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;
							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					gap: 2rem;
	
					& .info_imob{
				
						& .img{
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);
				
							& img{
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}
				
						& .descricao{
							& p{
								font-size: .85rem;
							}
				
							& a{
								& p{
									font-size: .85rem;
								}
							}
						}
					}
				}

				& .redes_sociais {
					gap: 1.5rem;
	
					& .item_rs {
						& a {
							padding: 1.1rem;
	
							& i {
								font-size: 1rem;
							}
						}
					}
				}
			}
	
			& .imagem_sobre {
				& img {
					width: calc(1080px / 2.75);
					height: calc(1621px / 2.75);
				}
			}
		}
	}

	.section_imoveis_por_regiao {
		& .container_regioes {
			gap: 1.5rem 1rem;
	
			& a {
				padding: .5rem 1rem;

				& .miniatura_regiao {
					& .localizacao {
						& .bairro {
							font-size: .95rem;
						}
					}
				}
			}
		}
	}

	.section_servicos{
		& .container_servicos{
			width: 100%;

			& .bloco_servico{
	
				& .img{
					width: 50px;
					height: 50px;
	
					& img{
						width: 50px;
						height: 50px;
					}
				}

				& .descricao{
	
					& h2{
						font-size: 1rem;
						line-height: 1.25rem;
					}
				}
			}
		}
	}
}


@media (max-width: 1023px){
		
	.width_vid{
		width: 80vw !important;
	}

	.title_condomi{
		& h2{
			font-size: 1.5rem;
		}

		& .under_p{
			width: 30%;
		}
	}

	.title_condomi2{
		& h2{
			font-size: 1.5rem;
		}

		& .under_p{
			width: 30%;
		}
	}

	.bg_busca_interna{
		& h2{
			font-size: 1.4rem;
		}
	
		& .container_filtros_busca_interna{
			grid-template-columns: repeat(1, 1fr);
			gap: 1.5rem;
		}
	}

	.section_sobre {
		background: linear-gradient(165deg, $colorPrimary 20%, white 20%);
		
		& .container_sobre {
			display: flex;
			flex-direction: column;
			flex-direction: column-reverse;
			gap: 1rem;
			grid-template-columns: none;
			
			& .conteudo {
				width: 100%;

				& h3 {
					font-size: 1.2rem;
				}
	
				& h2 {
					font-size: 1.3rem;
				}
	
				& p {
					font-size: .9rem;
				}
	
				& strong {
					font-size: .9rem;
				}

				& .btn_vermais {
					& a {
						& p {
							font-size: .9rem;
							& i {
								font-size: 1.1rem;
							}
						}
					}
				}

				& .infos_sobre {
					grid-template-columns: 1fr;
					gap: 2rem;
	
					& .info_imob{
				
						& .img{
							width: calc(256px / 7.5);
							height: calc(256px / 7.5);
				
							& img{
								width: calc(256px / 7.5);
								height: calc(256px / 7.5);
							}
						}
				
						& .descricao{
							& p{
								font-size: .85rem;
							}
				
							& a{
								& p{
									font-size: .85rem;
								}
							}
						}
					}
				}

				& .redes_sociais {
					justify-content: center;
					gap: 2rem;
	
					& .item_rs {
						& a {
							padding: 1.3rem;
	
							& i {
								font-size: 1.2rem;
								
							}
						}
					}
				}
			}
	
			& .imagem_sobre {
				text-align: center;
				
				& img {
					width: calc(1080px / 3.75);
					height: calc(1621px / 3.75);
					max-width: 90vw;
					margin: auto;
				}
			}
		}
	}

	.section_imoveis_por_regiao {
		& .title_imo_regiao {
			& h2{
				font-size: 1.5rem;
			}

			& .under_p{
				width: 30%;
			}
		}
	
		& .container_regioes {
			grid-template-columns: 1fr 1fr;
			gap: 1.5rem 1rem;
	
			& a {
				padding: 1rem;
			
				& .miniatura_regiao {
					& .localizacao {
						& .bairro {
							font-size: .9rem;
						}
	
						& .cid_est {
							color: white;
							font-size: .85rem;
							font-weight: 400;
						}
					}
				}
	
				&:hover {
					box-shadow: 0px 5px 5px 3px darken($colorPrimary, 5);
					top: -4px;
					border-color: rgba($colorTerciary, .5);
				}
			}
		}
	}

	.section_servicos{
		& .container_servicos{
			width: 100%;
			grid-template-columns: repeat(1, 1fr);
			gap: 2rem;

			& .bloco_servico{
				& .img{
					width: 50px;
					height: 50px;
	
					& img{
						width: 50px;
						height: 50px;
					}
				}
			}
		}
	}
}