.gallery {
	position: relative;
	width: 100%;

	& .galeria_imagens {
		width: 100%;
		height: 55vh;
		overflow: hidden;
		display: grid;
		grid-template-rows: repeat(2, 1fr);
		gap: 1px;
		grid-template-areas: "img1 img1 img2 img4" "img1 img1 img3 img5";
		grid-template-columns: repeat(4, 1fr);
		background-color: $gray5;

		& .thumb_img {
			width: 100%;
			height: 100%;
			overflow: hidden;

			& p {
				display: none;
			}

			& .img_galeria {
				height: 100%;
				width: 100%;
				position: relative;

				& img {
					width: 100%;
					height: 100%;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					object-fit: cover;
					user-select: none;
					-webkit-user-drag: none;
				}
			}
		}

		& .img1 {
			grid-area: img1;
			height: inherit;
		}

		& .img2 {
			grid-area: img2;
		}

		& .img3 {
			grid-area: img3;
		}

		& .img4 {
			grid-area: img4;
		}

		& .img5 {
			grid-area: img5;
			filter: blur(3px);
		}

	}

	& #prevButton_mob {
		position: absolute;
		top: 50%;
		left: 3%;
		transform: translate(-3%, -50%);

		background-color: transparent;
		color: $colorTerciary;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: all .2s ease-in-out;

		& i {
			font-size: 2.5rem;
			font-weight: 600;
			margin: 0;
			line-height: normal;
			text-shadow: -1px 1px 1px $colorSecondary;
		}

		&:hover{
			transform: translate(-3%, -50%) scale(1.2);
		}
	}

	& #nextButton_mob {
		position: absolute;
		top: 50%;
		right: 3%;
		transform: translate(-3%, -50%);

		background-color: transparent;
		color: $colorTerciary;
		border: none;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: all .2s ease-in-out;

		& i {
			font-size: 2.5rem;
			font-weight: 600;
			margin: 0;
			line-height: normal;
			text-shadow: 1px 1px 1px $colorSecondary;
		}

		&:hover{
			transform: translate(-3%, -50%) scale(1.2);
		}
	}

	& .btn_ver_todas {
		position: absolute;
		right: 6%;
		bottom: 20%;
		transform: translate(-6%, -20%);

		& button {
			border: solid 1px white;
			border-radius: .2rem;
			background-color: $colorTerciary;
			color: black;
			font-size: 1rem;
			font-weight: 600;
			padding: .5rem 1.5rem;
			cursor: pointer;
			transition: all .2s ease-in-out;
			&:hover{
				background-color: lighten($colorTerciary, 5);
			}
		}
	}

	& .btn_ver_todas_mob {
		position: absolute;
		top: 5%;
		right: 3%;
		transform: translate(-3%, -5%);

		& button {
			border: solid 1px white;
			border-radius: .2rem;
			background-color: rgba($gray1, .8);
			font-weight: 600;
			padding: .3rem .5rem;
			cursor: pointer;
			transition: all .2s ease-in-out;

			&::before{
				display: block;
				color: #FFF;
				content: "\f065";
				font-family: 'Objetiva Software Light' !important;
				font-size: 17px;
				text-align: center;
				transform-origin: center center;
				text-shadow: 0px 0px 1px rgba(0,0,0,0.5);
			}

			&:hover{
				background-color: lighten($colorTerciary, 5);
			}
		}
	}
}

#galeria_fullscreen {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(black, .95);
	z-index: 9999;
	display: flex;
	justify-content: center;
	align-items: center;
	
	& .container_imgs_fs {
		width: 80%;
		height: 80vh;
		border-radius: 1rem;
		background-color: $gray1;
		position: relative;

		& #imagens_gfs {
			width: 100%;
			height: 100%;
			display: grid;
			grid-auto-flow: column;
			grid-auto-columns: 1fr;
			position: relative;
			overflow: hidden;

			& .thumb_img {
				display: block;
				width: 100%;
				height: 100%;
				position: relative;
				
				& .img_galeria {
					width: 100%;
					height: 100%;
	
					& img {
						width: 100%;
						height: 100%;
						display: block;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
						object-fit: contain;
						user-select: none;
						-webkit-user-drag: none;
					}
				}
	
				& .contador_imagens {
					display: block;
					position: absolute;
					top: 2%;
					right: 1%;
					transform: translate(-1%, -2%);
					z-index: 1;
					margin: 0;
					background-color: rgba(black, .5);
					border: solid 1px white;
					color: white;
					font-size: .9rem;
					font-weight: 600;
					padding: .3rem 1rem;
					border-radius: .2rem;
				}

				& .legenda_img {
					display: block;
					position: absolute;
					bottom: 2%;
					left: 50%;
					transform: translate(-50%, -2%);
					z-index: 1;
					margin: 0;
					background-color: rgba(black, .5);
					color: white;
					font-size: .9rem;
					font-weight: 500;
					padding: .2rem 1rem;
					border-radius: .2rem;
					line-height: inherit;
				}

			}

			&:first-child {
				display: block; /* Exibe apenas a primeira imagem */
			}
		}

		& #prevButton {
			position: absolute;
			top: 50%;
			left: 3%;
			transform: translate(-3%, -50%);

			background-color: transparent;
			color: $colorTerciary !important;
			border: none;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: all .2s ease-in-out;

			& i {
				font-size: 2.5rem;
				font-weight: 600;
				margin: 0;
				line-height: normal;
				text-shadow: -1px 1px 1px $colorSecondary;
			}

			&:hover{
				transform: translate(-3%, -50%) scale(1.2);
			}
		}

		& #nextButton {
			position: absolute;
			top: 50%;
			right: 3%;
			transform: translate(-3%, -50%);

			background-color: transparent;
			color: $colorTerciary !important;
			border: none;
			display: flex;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			transition: all .2s ease-in-out;

			& i {
				font-size: 2.5rem;
				font-weight: 600;
				margin: 0;
				line-height: normal;
				text-shadow: 1px 1px 1px $colorSecondary;
			}

			&:hover{
				transform: translate(-3%, -50%) scale(1.2);
			}
		}
	}

	& #btn_close_gallery {
		position: absolute;
		top: 3%;
		right: 3%;
		transform: translate(-3%, -3%);
		margin: 0;
		background-color: transparent;
		color: white;
		width: 2.5rem;
		height: 2.5rem;
		border: none;
		border-radius: 50%;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;
		transition: all .2s ease-in-out;

		& i {
			font-size: 1.1rem;
			font-weight: 600;
			margin: 0;
			line-height: normal;
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px) {
	.gallery {
		position: relative;
		width: 100%;
	
		& .galeria_imagens {
			height: 60vh;
		}
	
		& .btn_ver_todas {
			right: 5%;
			bottom: 18%;
			transform: translate(-5%, -18%);
	
			& button {
				font-size: .9rem;
			}
		}
	}
}

@media (max-width: 1023px) {
	.gallery {
		width: 100%;
		max-width: 100vw;
		& .galeria_imagens {
			width: 100%;
			height: 50vh;
			overflow: hidden;
			grid-template-rows: inherit;
			grid-template-areas: inherit;
			grid-template-columns: inherit;
			background-color: $gray5;
			display: flex;
			gap: 1px;
			flex-direction: inherit;
			flex-wrap: initial;


			& .thumb_img {
				flex: 0 0 100%;
				position: relative;
				scroll-snap-align: start !important;
				flex-shrink: 0;
				transform-origin: center center;
				transform: scale(1);
				transition: transform 0.5s;
				position: relative;
				display: flex;
				justify-content: center;
				align-items: center;
	
				& .contador_imagens {
					display: block;
					position: absolute;
					left: 3%;
					top: 5%;
					transform: translate(-3%, -5%);
					z-index: 1;
					margin: 0;
					background-color: rgba(black, .5);
					color: white;
					font-size: .9rem;
					font-weight: 600;
					padding: .3rem .5rem;
					border-radius: .2rem;
				}

				& .legenda_img {
					display: none;
				}
			}

			& .img5 {
				grid-area: img5;
				filter: none;
			}
		}
	}

	#galeria_fullscreen {
		background-color: rgba(black, .9);
		
		& .container_imgs_fs {
			height: 60vh;
			width: 90%;
			border-radius: .5rem;

			& #imagens_gfs {
				& .thumb_img {
					& .contador_imagens {
						right: 3%;
						top: 3%;
						transform: translate(-3%, -3%);
						padding: .2rem .5rem;
					}
	
					& .legenda_img {
						max-width: 90%;
						width: max-content;
						text-align: center;
						line-height: normal;
					}
				}
			}
	
			& #prevButton {
				top: 50%;
				left: 1%;
				transform: translate(-1%, -50%);
	
				& i {
					font-size: 2rem;
                }
			}
	
			& #nextButton {
				top: 50%;
				right: 1%;
				transform: translate(-1%, -50%);
	
				& i {
					font-size: 2rem;
				}
			}
		}
	}
}