.nav {
	display: block;
	width: 20vw;
	position: fixed;
	top: 0;
	right: -1000px;
	background-color: white;
	z-index: 99991;
	padding: 0 1rem;
	box-shadow: -5px 0 10px -5px rgba(black, .2);

	& button {
		margin-right: 0;
		background-color: transparent;
		color: $colorSecondary;
		border: none;
		font-size: 2rem;
		cursor: pointer;

		& i {
			line-height: inherit;
			color: $colorPrimary;
		}
	}

	& .nav-flow {
		min-height: 50vh;
		height: 100vh;
	}

	& ul{
		& li{
			& a{
				& .nav-menu {
					color: $gray1;
					font-size: 1rem;
					font-weight: 400;
					margin: 0;
					padding: .5rem .5rem;
					@include border-box;
					transition: all .25s;
					border-left: solid 1px transparent;
					
					&:hover{
						border-color: $colorTerciary;
						padding-left: 1rem;
						background-color: $gray5;
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1366px){
	.nav{
		width: 28vw;
	}
}

@media (max-width: 1023px){
	.nav{
		width: 100%;
		overflow: auto;
		background-color: rgba(white, .99);
		& .nav-menu {
			color: $gray1;
			font-size: 1.4rem;
			margin: 0;
			padding: .6rem 1rem;
			@include border-box;
			transition: all .25s;
		}
		& .nav-flow {
			min-height: 50vh;
			height: 110vh;
		}
		& .content {
			padding-bottom: 10rem !important;
		}
	}
}