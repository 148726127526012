#boss-dialog, .boss-dialog{
	position: fixed;
	z-index: 10000;
	top: 0px;
	left: 0px;
	background-color: rgba(0,0,0,0.7);
	width: 100vw;
	height: 100vh;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
}
.boss-dialog-area{
	position: relative;
	display: block;
	max-width: 50vw;
	width: 50vw;
	height: auto;
	border-radius: .3rem;
	background-color: #ffffff;
	border: none;
	padding: 1rem;
	@include border-box;
}

#busca-filtros{
	background-color: white;
	overflow: auto;
	height: 50vh;
	padding: 1rem;
	display: grid;
	grid-template-columns: repeat(1, 1fr);
	row-gap: 1rem;
}

.btns_ver_resultados_infras {
	display: block;
	width: 100%;
	padding-top: 1rem;

	& .btn_pc_Infras {
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: 1rem;
	}
}

.boss-dialog-img{
	display: block;
	max-width: calc(100vw - 40px);
	max-height: calc(100vh - 60px);
}
.boss-dialog-invisible{
	background-color: rgba(0,0,0,0) !important;
	border: none !important;
}
.boss-dialog-area iframe{
	border: none;
	position: relative;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-ms-box-sizing: border-box;
	-o-box-sizing: border-box;
}
.boss-dialog-close{
	cursor: pointer;
	position: absolute;
	top: -17px;
	right: -17px;
	font-size: 18px;
	background-color: white;
	border: 2px solid $colorTerciary;
	color: $colorPrimary;
	border-radius: 50%;
	width: 36px;
	line-height: 32px;
	padding-top: 0 !important;
	padding-bottom: 0 !important;
	z-index: 10;
	transition: all .2s ease-in-out;

	&:hover, &:active, &:focus{
		color: white;
		background-color: $colorTerciary;
	}
}

@media (min-width: 1201px) and (max-width: 1370px){
	#busca-filtros{
		background-color: white;
		overflow: auto;
		height: 56vh;
	}
}
@media (min-width: 1024px) and (max-width: 1200px){
	
	#busca-filtros{
		background-color: white;
		overflow: auto;
		height: 52vh;
	}
}

@media (max-width: 1023px){
	
	.boss-dialog-area{
		position: relative;
		display: block;
		/* max-width: calc(100vw - 20px);
		max-height: calc(100vh - 80px); */
		width: 90vw;
		max-width: 90vw;
		height: auto;
		background-color: #ffffff;
		border: none;
		padding: 1rem;
		//min-width: 32rem;
		@include border-box;
	}

	#busca-filtros{
		background-color: white;
		overflow: auto;
		height: 50vh;
	}
}