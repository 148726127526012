.section_imobiliaria{
    display: block;
    width: 100%;

    & h1{
        font-size: 2rem;
        font-weight: 600;
        color: $colorPrimary;
    }

    & h2{
        font-size: 2rem;
        font-weight: 600;
        color: $colorPrimary;
    }

    & p{
        font-size: .9rem;
        font-weight: 400;
        color: black;
    }

    & ol {
        & li {
            list-style-type: decimal !important;
            font-size: .9rem;
            font-weight: 400;
            color: black;
            line-height: 1.5rem;
        }
    }

    & strong {
        font-weight: 600;
    }
}
