.footer1 {
	border-top: solid 3px $colorTerciary;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: $colorPrimary;

	& .contentFooter {
		width: 70%;
		display: grid;
		grid-template-columns: 2fr .5fr;
		gap: 3rem;
		padding: 3rem 0;
		margin: auto;
	
		& .footerLeft {
			margin: 0;
			width: fit-content;
			display: flex;
			flex-direction: column;
			gap: 2rem;

			& .logo_footer {
				margin: 0;

				& a {
					width: calc(801px / 5);
					height: calc(801px / 5);
					display: block;
					text-align: left;
	
					& img{
						width: calc(801px / 5);
						height: calc(801px / 5);
						user-select: none;
						-webkit-user-drag: none;
					}
				}
			}

			& ul {
				width: fit-content;
				display: flex;
				gap: 1.5rem;

				& li{
					width: fit-content;
					margin: 0;

					& a{
						width: fit-content;
						display: block;
						
						& p{
							font-size: .9rem;
							color: white;
							margin: 0;
							padding: 0;
							line-height: normal;
							transition: color .2s ease-in-out;
							user-select: none;
							text-transform: uppercase;
							font-weight: 600;

							&:hover {
								color: $colorTerciary;
							}
						}
					}
				}
			}
		}

		& .footerRight {
			margin: 0;
			width: fit-content;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 1.5rem;

			& .redes_sociais_footer {
				display: flex;
				flex-direction: row;
				justify-content: flex-start;
				align-items: center;
				gap: 2rem;
				margin: 0;

				& a {
					& i {
						font-size: 3rem;
						color: white;
						transition: color .2s ease-in-out;
					}

					&:hover {
						& i {
							color: $colorTerciary;
						}
					}
				}
			}

			& .endereco_footer {
				width: 100%;
				display: flex;
				margin: 0;

				& p {
					margin: 0;
					color: white;
					font-size: .9rem;
					font-weight: 600;
					line-height: 1.75rem;
				}
			}

			& .foneCreci {
				margin: 0;
				width: 100%;
				display: flex;
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;
				gap: 1.5rem;

				& a {
					& p {
						color: $colorTerciary;
						margin: 0;
						font-size: 1rem;
						font-weight: 600;
						transition: color .2s ease-in-out;

						&:hover {
							color: lighten($colorTerciary, 5);
						}
					}
				}

				& p {
					color: white;
					margin: 0;
					font-size: .9rem;
					font-weight: 600;
				}
			}
		}
	}

	& .ContainerInferiorFooter{
		width: 100%;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: black;
		
		& .ItemContainerInferior{
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			width: 100%;
			padding: .75rem 0;

			& p{
				margin: 0;
				font-size: .8rem;
				font-weight: 400;
				color: white;
				user-select: none;
				line-height: normal;

				& a{
					& span{
						color: white;
						font-weight: 600;
						font-size: .8rem;
						transition: color .2s ease-in-out;
					}

					&:hover{
						& span{
							color: $colorTerciary;
						}
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) and (max-width: 1490px){
	.footer1 {
		& .contentFooter {
			width: 85%;
		}
	}
}

@media (max-width: 1023px){
	.footer1 {
		& .contentFooter {
			width: 100%;
			grid-template-columns: 1fr;
			gap: 5rem;
			padding: 3rem 1rem;
		
			& .footerLeft {
				width: 100%;
				gap: 3rem;
	
				& .logo_footer {
					margin: auto;
	
					& a {
						width: calc(801px / 6);
						height: calc(801px / 6);
		
						& img{
							width: calc(801px / 6);
							height: calc(801px / 6);
						}
					}
				}
	
				& ul {
					width: 100%;
					justify-content: center;
					align-items: center;
					gap: 1.5rem;
	
					& li{
	
						& a{
							& p{
								font-size: .8rem;
							}
						}
					}
				}
			}
	
			& .footerRight {
				margin: 0;
				width: 100%;
				justify-content: center;
				align-items: center;
				gap: 3rem;
	
				& .redes_sociais_footer {
					justify-content: center;
					align-items: center;
					gap: 5rem;
				}
	
				& .endereco_footer {
					& p {
						width: 100%;
						text-align: center;
					}
				}
	
				& .foneCreci {
					justify-content: center;
					align-items: center;
					gap: 3rem;
				}
			}
		}
	
		& .ContainerInferiorFooter{
			& .ItemContainerInferior{
				padding: 1rem;
	
				& p{
					text-align: center;
				}
			}
		}
	}
}

/*
	LARGE SCREEN 
	max-width: 1370px
	min-width: 1201px
*/
@mixin footer--large(){
}
/* 
	MEDIUM SCREEN 
	max-width: 1300px
	min-width: 1024px
*/
@mixin footer--medium(){
}
/*
	TABLET LANDSCAPE 
	max-width: 1023px
	min-width: 768px
*/
@mixin footer--tablet-landscape(){
}
/*
	TABLET PORTRAIT 
	max-width: 767px
	min-width: 641px
*/
@mixin footer--tablet-portrait(){
}
/*
	SMARTPHONE LANDSCAPE
	max-width: 640px
	min-width: 482px
*/
@mixin footer--smart-landscape(){
}
/*
	SMARTPHONE PORTRAIT
	max-width: 481px
	min-width: 321px
*/
@mixin footer--smart-portrait(){
}
/*
	OLD SMARTPHONE
	max-width: 320px
*/
@mixin footer--smart-old(){
}